import React, { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../contexts/ChatContext.js";
import { useIntl } from "react-intl";
import "../styles/QuestionPage.scss";
import HxgHeader from "../components/HxgHeader.js";
import { sendHxg } from "../services/apiRequest.js";
import { SessionContext } from "../contexts/sessionContext.js";
import userLog from "../utils/userLog.js";

const QuestionPage = (props) => {
  const navigate = useNavigate();
  const abortController = useRef(new AbortController());
  const intl = useIntl();
  const { LANG } = props;
  const { question, classify, setQuestion, setFilterRes, webSessionId } =
    useContext(ChatContext);
  const { userSign, browserFingerPrint } = useContext(SessionContext);
  const [padding, setPadding] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const langMap = { en: "en", "zh-TW": "ch" };

  const filterPostData = {
    question,
    language: langMap[LANG],
    questionType: classify,
    agentId: browserFingerPrint,
  };

  const enterOnClick = () => {
    setErrorMessage("");
    if (question) startProcess();
  };

  const startProcess = () => {
    setPadding(true);
    sendHxg(
      langMap[LANG],
      filterPostData,
      abortController.current,
      (err, res) => {
        setPadding(false);
        if (err) {
          setErrorMessage(err);
        } else {
          if (res.passed) {
            setFilterRes(res);
            userLog("Ques_short", userSign, LANG, webSessionId, (err, res) => {
              if (err) {
                console.log(err);
              }
            });
            navigate("/moreinfopage");
          } else {
            setErrorMessage(res.content);
          }
        }
      }
    );
  };

  return (
    <>
      <HxgHeader>
        <img
          src={`${process.env.PUBLIC_URL}/imgs/askQuestion.png`}
          className="QuestionPage__askQuestionImg"
          alt=""
        />
        <textarea
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          className="QuestionPage__setQuestion"
        />
        <div className="QuestionPage-enter">
          <div className="QuestionPage-enter__button" onClick={enterOnClick}>
            <div className="QuestionPage-enter__text">▶</div>
          </div>
        </div>
        <div className="QuestionPage__info">
          {padding && (
            <div>{intl.formatMessage({ id: "ChatProcess.pleaseWait" })}</div>
          )}
          {!!errorMessage && errorMessage}
        </div>
      </HxgHeader>
    </>
  );
};

export default QuestionPage;
