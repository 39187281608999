import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../contexts/ChatContext.js";
import { SessionContext } from "../contexts/sessionContext.js";
import { useIntl } from "react-intl";
import {
  sendHxg,
  createPayment,
  showSessionContent,
  webCreditCount,
  feedbackGetQuestion,
  feedbackSubmit,
} from "../services/apiRequest.js";
import "../styles/DivinationPage.scss";
import CheckoutForm from "../components/CheckoutForm.js";
import HxgHeader from "../components/HxgHeader.js";
import userLog from "../utils/userLog.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Cookies from "js-cookie";

const DivinationPage = (props) => {
  const { LANG } = props;
  const navigate = useNavigate();
  const intl = useIntl();
  const dollar = "USD$2";
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const { webSessionId, question, classify, tossResult, info, filterRes } =
    useContext(ChatContext);
  const { userSign, browserFingerPrint } = useContext(SessionContext);
  const [showText, setShowText] = useState([]);
  const [ans, setAns] = useState();
  const [aiAskMe, setAiAskMe] = useState();
  const [padding, setPadding] = useState(false);
  const [aiAskMeAns, setAiAskMeAns] = useState("");
  const [remainChangeQuestion, setremainChangeQuestion] = useState(0);
  const [showAskAiPopUp, setShowAskAiPopUp] = useState(false);
  const [showLinePopUp, setShowLinePopUp] = useState(false);
  const [askAi, setAskAi] = useState("");
  const [remainInteractive, setRemainInteractive] = useState(false);
  const [isShortAns, setIsShortAns] = useState(false);
  const [isShowPay, setIsShowPay] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [remindCreditsCount, setRemindCreditsCount] = useState("");
  const [remindFreeCreditsCount, setRemindFreeCreditsCount] = useState("");
  const [showtextIndex, setShowtextIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [copied, setCopied] = useState(false);

  const questionIds = [1, 2];
  const [feedBackQuestion, setFeedBackQuestion] = useState([
    { id: "", question: "" },
    { id: "", question: "" },
  ]);
  const [feedBackPopUp, setFeedBackPopUp] = useState(false);
  const [feedBackAns, setFeedBackAns] = useState([
    { id: "", ans: "" },
    { id: "", ans: "" },
  ]);
  const [feedBackPadding, setFeedBackPadding] = useState([false, false]);

  const abortController = useRef(new AbortController());
  const langMap = { en: "en", "zh-TW": "ch" };
  const questionType = classify;

  const filterPostData = {
    question,
    language: langMap[LANG],
    questionType,
    agentId: browserFingerPrint,
  };

  const handleCopy = async () => {
    if (!webSessionId) return; // 避免空白時複製
    try {
      await navigator.clipboard.writeText(`/history:${webSessionId}`);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("複製失敗", err);
      handleCopyFallback();
    }
  };

  const closeLineOnClick = () => {
    setShowLinePopUp(false);
  };
  const closFeedBackOnClick = () => {
    setFeedBackPopUp(false);
  };

  const feedBackEnterOnClick = () => {
    console.log(feedBackAns);
    feedBackAns.forEach((e, index) => {
      updateFeedbackPadding(index, true);
      if (e.ans) {
        feedbackSubmit(
          LANG,
          {
            agentIdOrEmail: browserFingerPrint,
            questionId: e.id,
            feedbackContent: e.ans,
          },
          abortController.current,
          (err, res) => {
            updateFeedbackPadding(index, false);
            if (!feedBackPadding.some((value) => value === true))
              setFeedBackPopUp(false);
          }
        );
      } else {
        updateFeedbackPadding(index, false);
      }
    });
  };

  const handleCopyFallback = () => {
    const textarea = document.createElement("textarea");
    textarea.value = `/history:${webSessionId}`;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const leftButtonOnClick = () => {
    if (showtextIndex < showText?.length - 1)
      setShowtextIndex(showtextIndex + 1);
  };

  const rightButtonOnClick = () => {
    if (showtextIndex > 0) setShowtextIndex(showtextIndex - 1);
  };

  const handleShowTextAdd = (newItem) => {
    setShowText((prevArray) => [...prevArray, newItem]);
    setShowtextIndex(0);
  };

  const handleShowTextUpdate = (index, newValue) => {
    setShowText((prevArray) =>
      prevArray.map((item, i) => (i === index ? newValue : item))
    );
  };

  const updateFeedbackQuestion = (index, newValue) => {
    setFeedBackQuestion((prev) =>
      prev.map((item, i) => (i === index ? newValue : item))
    );
  };

  const updateFeedbackAns = (index, newValue) => {
    setFeedBackAns((prev) =>
      prev.map((item, i) => (i === index ? newValue : item))
    );
  };

  const updateFeedbackPadding = (index, newValue) => {
    setFeedBackPadding((prev) =>
      prev.map((item, i) => (i === index ? newValue : item))
    );
  };

  const updateCreditDisplay = () => {
    webCreditCount(
      langMap[LANG],
      { agentId: browserFingerPrint, append: true },
      abortController.current,
      async (error, resQ) => {
        if (error) {
          setRemindFreeCreditsCount("?");
          setRemindCreditsCount("?");
        } else {
          if (resQ) {
            const remainCredits = resQ.credit.total;
            const remainFreeCredits = resQ.credit.freeCredits;
            setRemindFreeCreditsCount(remainFreeCredits);
            setRemindCreditsCount(remainCredits - remainFreeCredits);
          }
        }
      }
    );
  };

  const payOnClick = (e) => {
    const postData = { sessionId: webSessionId };
    createPayment(
      langMap[LANG],
      postData,
      abortController.current,
      (err, res) => {
        if (err) {
          setErrorMessage(err);
        } else {
          setClientSecret(res.client_secret);
          setIsShowPay(true);
        }
      }
    );
    //for testing
    // handleSuccessPay();
  };

  const handleSuccessPay = () => {
    const postData = { sessionId: webSessionId };
    setPadding(true);
    showSessionContent(
      langMap[LANG],
      postData,
      abortController.current,
      (err, res) => {
        setPadding(false);
        if (err) {
          setErrorMessage(err);
        } else {
          setIsShortAns(res.shortAns);
          setRemainInteractive(res.remainInteractiveTime);
          setAns(res.messages.at(-1).content);
          handleShowTextAdd(res.messages.at(-1).content);
        }
      }
    );
  };

  const startProcess = (visited) => {
    updateCreditDisplay();
    if (filterRes) {
      handleDivination(filterRes, visited);
    } else {
      sendHxg(
        langMap[LANG],
        filterPostData,
        abortController.current,
        (err, res) => {
          if (err) {
            setErrorMessage(err);
          } else {
            if (res.passed) {
              handleDivination(res, visited);
            } else {
              setErrorMessage(res.content);
            }
          }
        }
      );
    }
  };
  const askAiEnterOnClick = () => {
    if (!askAi || askAi === "") return;
    setShowAskAiPopUp(false);
    setPadding(true);
    const postData = {
      agentId: browserFingerPrint,
      isotime: new Date().valueOf(),
      language: langMap[LANG],
      question: askAi,
      questionType,
      sessionId: webSessionId,
      uHash: userSign,
      userInterQuestion: 1,
      userhexagram: tossResult,
    };
    sendHxg(
      langMap[LANG],
      postData,
      abortController.current,
      async (err, res) => {
        setPadding(false);
        if (err) {
          console.log(err);
        } else {
          try {
            if (res) {
              setRemainInteractive(res.remainInteractive);
              setAns(res.aiAnswer);
              handleShowTextAdd(`問:${askAi}\n${res.aiAnswer}`);
              setAskAi("");
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    );
  };
  const askAiOnClick = () => {
    setShowAskAiPopUp(true);
  };

  const lineOnClick = () => {
    setShowLinePopUp(true);
  };

  const aiAskMeOnClick = () => {
    setPadding(true);
    const postData = {
      userhexagram: tossResult,
      question: question,
      language: langMap[LANG],
      isotime: new Date().valueOf(),
      uHash: userSign,
      sessionId: webSessionId,
      agentId: browserFingerPrint,
      questionType,
      aiInterQuestion: 1,
    };
    sendHxg(
      langMap[LANG],
      postData,
      abortController.current,
      async (err, res) => {
        setPadding(false);
        if (err) {
          console.log(err);
        } else {
          try {
            if (res) {
              setRemainInteractive(res.remainInteractive);
              setremainChangeQuestion(res.remainChangeQuestion);
              setAiAskMeAns("");
              setAiAskMe(res.aiQuestion);
              handleShowTextAdd(res.aiQuestion);
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    );

    //   setTimeout(() => {
    //     setPadding(false);
    //     setAiAskMe("aiaskme aiaskme......");
    //     handleShowTextAdd("aiaskme aiaskme......");
    //   }, 2000);
  };

  const aiAskMeAnsOnClick = () => {
    if (!aiAskMeAns || aiAskMeAns === "") return;
    const postData = {
      agentId: browserFingerPrint,
      language: langMap[LANG],
      question: aiAskMeAns,
      questionType,
      sessionId: webSessionId,
      userAnsQ: 1,
      userInterQuestion: 1,
      userhexagram: tossResult,
    };
    setPadding(true);
    handleShowTextUpdate(
      showText.length - 1,
      `${showText[showText.length - 1]}\n回答:${aiAskMeAns}`
    );
    setremainChangeQuestion(0);
    sendHxg(
      langMap[LANG],
      postData,
      abortController.current,
      async (err, res) => {
        setPadding(false);
        if (err) {
          console.log(err);
        } else {
          try {
            if (res) {
              setRemainInteractive(res.remainInteractive);
              setAiAskMe("");
              setAns(res.aiAnswer);
              handleShowTextAdd(res.aiAnswer);
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    );
  };

  const changQuestionOnClick = () => {
    if (remainChangeQuestion > 0) {
      const postData = {
        agentId: browserFingerPrint,
        aiInterQuestion: 1,
        changeQuestion: 1,
        isotime: new Date().valueOf(),
        language: langMap[LANG],
        question: "",
        questionType,
        sessionId: webSessionId,
        uHash: userSign,
        userhexagram: tossResult,
      };
      setPadding(true);
      sendHxg(
        langMap[LANG],
        postData,
        abortController.current,
        async (err, res) => {
          setPadding(false);
          if (err) {
            console.log(err);
          } else {
            try {
              if (res) {
                setremainChangeQuestion(res.remainChangeQuestion);
                setAiAskMe(res.aiQuestion);
                handleShowTextAdd(res.aiQuestion);
              }
            } catch (e) {
              console.log(e);
            }
          }
        }
      );
    }
  };

  useEffect(() => {
    const handleBack = (event) => {
      event.preventDefault();

      const userConfirmed = window.confirm(
        intl.formatMessage({
          id: "ChatProcess.closeWarning",
        })
      );
      if (userConfirmed) {
        navigate(-1); // 真正返回上一頁
      } else {
        window.history.pushState(null, "", window.location.href); // 讓用戶停留
      }
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBack);

    return () => {
      window.removeEventListener("popstate", handleBack);
    };
  }, [navigate]);

  useEffect(() => {
    let visited = false;
    if (!question) {
      userLog("Chat_refresh", userSign, LANG, webSessionId, (err, res) => {
        if (err) console.log(err);
      });
      navigate("/");
    } else {
      if (ans) return;
      userLog("Chat_enter", userSign, LANG, webSessionId, (err, res) => {
        if (err) console.log(err);
      });
      if (!Cookies.get("visited")) {
        setFeedBackPopUp(true);
        Cookies.set("visited", "true");
        questionIds.forEach((id, index) => {
          feedbackGetQuestion(
            LANG,
            { feedbackId: id },
            abortController.current,
            (err, res) => {
              updateFeedbackQuestion(index, { id, question: res.question });
              updateFeedbackAns(index, { id, ans: "" });
            }
          );
        });
      } else {
        visited = true;
      }
      startProcess(visited);
      // setAns("");
      // setTimeout(() => {
      //   setAns("ansansans.....");
      //   handleShowTextAdd("ansansans.....");
      // }, 2000);
    }
  }, []);

  const handleDivination = (filterRes, visited) => {
    const locPicPath = LANG === "en" ? "iching_div_jp" : "iching_div_ch";
    const postData = {
      classifyResult: filterRes,
      userhexagram: tossResult,
      question: question,
      language: langMap[LANG],
      isotime: new Date().valueOf(),
      uHash: userSign,
      sessionId: webSessionId,
      agentId: browserFingerPrint,
      questionType,
    };
    if (info) postData.extraInfo = info;
    const startTime = Date.now();
    sendHxg(
      langMap[LANG],
      postData,
      abortController.current,
      async (err, res) => {
        if (err) {
          console.log(err);
        } else {
          const elapsedTime = Date.now() - startTime;
          let remainingTime = Math.max(20000 - elapsedTime, 0);
          if (visited) remainingTime = 0;
          setTimeout(() => {
            try {
              if (res) {
                if (showText.length === 0) {
                  userLog(
                    "Chat_success",
                    userSign,
                    LANG,
                    webSessionId,
                    (err, res) => {
                      if (err) console.log(err);
                    }
                  );
                }
                updateCreditDisplay();
                setIsShortAns(res.shortAns);
                setRemainInteractive(res.remainInteractive);
                setAns(res?.answer?.choices[0]?.message?.content);
                handleShowTextAdd(res?.answer?.choices[0]?.message?.content);
              }
            } catch (e) {
              console.log(e);
            }
          }, remainingTime);
        }
      }
    );
  };

  return (
    <>
      <HxgHeader>
        <img
          src={`${process.env.PUBLIC_URL}/imgs/div02.png`}
          className="DivinationPage__titleImg"
          alt=""
        />
        <div className="DivinationPage-coin">
          <img
            alt=""
            src={`${process.env.PUBLIC_URL}/imgs/coin.png`}
            className="DivinationPage-coin__coinImg"
          />
          {remindCreditsCount}
          ❤️
          {`${remindFreeCreditsCount}${intl.formatMessage({
            id: "ChatProcess.preDay",
          })}`}
        </div>

        <div className="DivinationPage-textBox">
          <div>
            {!!showText.length && showText[showText.length - 1 - showtextIndex]}
          </div>
          {showtextIndex === 0 && remainChangeQuestion > 0 && (
            <div
              className="DivinationPage-textBox__changQuestionButton"
              onClick={changQuestionOnClick}
            >
              {intl.formatMessage({ id: "ChatProcess.changeQuestion" })}
            </div>
          )}
          {!!errorMessage && errorMessage}
        </div>
        <div className="DivinationPage-history">
          {showText?.length > 1 && showtextIndex < showText?.length - 1 && (
            <div
              className="DivinationPage-history__leftButton"
              onClick={leftButtonOnClick}
            >
              ◀
            </div>
          )}
          {showtextIndex !== 0 && (
            <div
              className="DivinationPage-history__rightButton"
              onClick={rightButtonOnClick}
            >
              ▶
            </div>
          )}
        </div>
        <div className="DivinationPage-infoBox">
          {!ans && intl.formatMessage({ id: "ChatProcess.firstHxg" })}
          {ans && !!isShortAns && (
            <div>
              {intl.formatMessage(
                { id: "ChatProcess.unlockHints" },
                { dollar }
              )}
              <div
                className="DivinationPage-infoBox__fullLineButton"
                onClick={payOnClick}
              >
                {intl.formatMessage({ id: "ChatProcess.payToUnlock" })}
              </div>
            </div>
          )}
          {showtextIndex === 0 &&
            !padding &&
            ans &&
            !aiAskMe &&
            remainInteractive > 0 && (
              <div>
                {ans &&
                  intl.formatMessage({ id: "ChatProcess.interactiveTimes" })}
                {ans && (
                  <div className="DivinationPage-infoBox-buttonSet">
                    <div
                      className="DivinationPage-infoBox-buttonSet__button"
                      onClick={aiAskMeOnClick}
                    >
                      {intl.formatMessage({ id: "ChatProcess.masterAskMe" })}
                    </div>
                    <div
                      className="DivinationPage-infoBox-buttonSet__button"
                      onClick={askAiOnClick}
                    >
                      {intl.formatMessage({ id: "ChatProcess.youAskAi" })}
                    </div>
                    <div
                      className="DivinationPage-infoBox-buttonSet__button"
                      onClick={lineOnClick}
                    >
                      {intl.formatMessage({ id: "ChatProcess.line" })}
                    </div>
                  </div>
                )}
              </div>
            )}
          {showtextIndex === 0 && !padding && ans && aiAskMe && (
            <div className="DivinationPage-infoBox-aiAskMeAnsInput">
              <textarea
                value={aiAskMeAns}
                onChange={(e) => setAiAskMeAns(e.target.value)}
                className="DivinationPage-infoBox-aiAskMeAnsInput__textarea"
                placeholder={intl.formatMessage({
                  id: "ChatProcess.ansPlease",
                })}
              />
              <div
                className="DivinationPage-infoBox-aiAskMeAnsInput-enter"
                onClick={aiAskMeAnsOnClick}
              >
                <div className="DivinationPage-infoBox-aiAskMeAnsInput-enter__text">
                  ▶
                </div>
              </div>
            </div>
          )}
          {showtextIndex === 0 &&
            !padding &&
            !aiAskMe &&
            remainInteractive === 0 && (
              <div>
                {intl.formatMessage({ id: "ChatProcess.endText" })}
                <div
                  className="DivinationPage-infoBox__fullLineButton"
                  onClick={lineOnClick}
                >
                  {intl.formatMessage({ id: "ChatProcess.line" })}
                </div>
              </div>
            )}
          {padding && (
            <div>{intl.formatMessage({ id: "ChatProcess.pleaseWait" })}</div>
          )}
        </div>
        {showAskAiPopUp && (
          <div className="DivinationPage-askAiPopUp">
            <div className="DivinationPage-askAiPopUp-content">
              <textarea
                value={askAi}
                onChange={(e) => setAskAi(e.target.value)}
                className="DivinationPage-askAiPopUp-content__textarea"
                placeholder={intl.formatMessage({
                  id: "ChatProcess.questionPlease",
                })}
              />
              <div
                className="DivinationPage-askAiPopUp-content-enter"
                onClick={askAiEnterOnClick}
              >
                <div className="DivinationPage-askAiPopUp-content-enter__text">
                  ▶
                </div>
              </div>
            </div>
          </div>
        )}
        {showLinePopUp && (
          <div className="DivinationPage-linePopUp">
            <div
              className="DivinationPage-linePopUp__closeButton"
              onClick={closeLineOnClick}
            >
              X
            </div>
            <div className="DivinationPage-linePopUp-content">
              <div
                id="line-button-container"
                className="DivinationPage-linePopUp-content-text"
              >
                {intl.formatMessage({
                  id: "ChatProcess.linePopUpText1",
                })}
                <br />
                <br />
                {intl.formatMessage({
                  id: "ChatProcess.linePopUpText2",
                })}
                <br />
                /history:{webSessionId}
                <br />
                <div
                  className="DivinationPage-linePopUp-content-text__copyButton"
                  onClick={handleCopy}
                >
                  {copied
                    ? intl.formatMessage({
                        id: "ChatProcess.copied",
                      })
                    : intl.formatMessage({
                        id: "ChatProcess.copy",
                      })}
                </div>
                <br /> <br />
                {intl.formatMessage({
                  id: "ChatProcess.linePopUpText3",
                })}
                <br />
                {intl.formatMessage({
                  id: "ChatProcess.linePopUpText4",
                })}
                <br />
                <a
                  href="https://line.me/R/ti/p/@209svkgm"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="DivinationPage-linePopUp-content-text__lineFriendButton"
                >
                  LINE
                </a>
              </div>
            </div>
          </div>
        )}
        {feedBackPopUp && (
          <div className="DivinationPage-feedBackPopUp">
            <div
              className="DivinationPage-feedBackPopUp__closeButton"
              onClick={closFeedBackOnClick}
            >
              X
            </div>
            <div className="DivinationPage-feedBackPopUp-content">
              <div className="DivinationPage-feedBackPopUp-content-text">
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/imgs/feedBackTitle.png`}
                  className="DivinationPage-feedBackPopUp-content__Img"
                />
                <div>{feedBackQuestion[0].question}(1P)</div>
                <textarea
                  value={feedBackAns[0].ans}
                  onChange={(e) =>
                    updateFeedbackAns(0, {
                      id: feedBackAns[0].id,
                      ans: e.target.value,
                    })
                  }
                  className="DivinationPage-feedBackPopUp-content__Ans"
                />
                <div>{feedBackQuestion[1].question}(1P)</div>
                <textarea
                  value={feedBackAns[1].ans}
                  onChange={(e) =>
                    updateFeedbackAns(1, {
                      id: feedBackAns[1].id,
                      ans: e.target.value,
                    })
                  }
                  className="DivinationPage-feedBackPopUp-content__Ans"
                />
                <br />
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/imgs/enter.png`}
                  className="DivinationPage-feedBackPopUp-content__enter"
                  onClick={feedBackEnterOnClick}
                />
              </div>
            </div>
          </div>
        )}
        {isShowPay && (
          <Elements
            options={{
              clientSecret,
              appearance: {
                theme: "stripe",
              },
            }}
            stripe={stripePromise}
          >
            <CheckoutForm
              clientSecret={clientSecret}
              changeShowPay={() => {
                setIsShowPay(false);
              }}
              handleSuccessPay={handleSuccessPay}
            />
          </Elements>
        )}
      </HxgHeader>
    </>
  );
};

export default DivinationPage;
