import { post, postAll, get } from "../utils/HttpRequest.js";

/* const apiUrl = `${
  LANG === "en"
    ? process.env.REACT_APP_API_EN_URL
    : process.env.REACT_APP_API_TC_URL
}`; */

export const feedbackSubmit = (LANG, postData, abortController, cb) => {
  const url = `${
    LANG === "en"
      ? process.env.REACT_APP_API_EN_URL
      : process.env.REACT_APP_API_TC_URL
  }/feedback/submit`;
  post(url, postData, null, abortController, null, (err, res) => {
    if (err) {
      cb(err);
    } else {
      cb(null, res);
    }
  });
};

export const feedbackGetQuestion = (LANG, postData, abortController, cb) => {
  const url = `${
    LANG === "en"
      ? process.env.REACT_APP_API_EN_URL
      : process.env.REACT_APP_API_TC_URL
  }/feedback/get_question`;
  post(url, postData, null, abortController, null, (err, res) => {
    if (err) {
      cb(err);
    } else {
      cb(null, res);
    }
  });
};

export const sendHxg = (LANG, postData, abortController, cb) => {
  const url = `${
    LANG === "en"
      ? process.env.REACT_APP_API_EN_URL
      : process.env.REACT_APP_API_TC_URL
  }/askhxg`;
  post(url, postData, null, abortController, null, (err, res) => {
    if (err) {
      cb(err);
    } else {
      cb(null, res);
    }
  });
};

export const getSession = (LANG, abortController, cb) => {
  const url = `${
    LANG === "en"
      ? process.env.REACT_APP_API_EN_URL
      : process.env.REACT_APP_API_TC_URL
  }/auth_user`;
  postAll(url, null, null, abortController, null, (err, res) => {
    if (err) {
      cb(err);
    } else {
      cb(null, res.headers.get("Session-Id"));
    }
  });
};

export const showSessionContent = (LANG, postData, abortController, cb) => {
  const url = `${
    LANG === "en"
      ? process.env.REACT_APP_API_EN_URL
      : process.env.REACT_APP_API_TC_URL
  }/showSessionContent`;
  post(url, postData, null, abortController, null, (err, res) => {
    if (err) {
      cb(err);
    } else {
      cb(null, res);
    }
  });
};

export const createPayment = (LANG, postData, abortController, cb) => {
  const url = `${
    LANG === "en"
      ? process.env.REACT_APP_API_EN_URL
      : process.env.REACT_APP_API_TC_URL
  }/createPayment`;
  post(url, postData, null, abortController, null, (err, res) => {
    if (err) {
      cb(err);
    } else {
      cb(null, res);
    }
  });
};

export const webCodeRedeem = (LANG, postData, abortController, cb) => {
  const url = `${
    LANG === "en"
      ? process.env.REACT_APP_API_EN_URL
      : process.env.REACT_APP_API_TC_URL
  }/webCodeRedeem`;
  post(url, postData, null, abortController, null, (err, res) => {
    if (err) {
      cb(err);
    } else {
      cb(null, res);
    }
  });
};

export const webCreditCount = (LANG, postData, abortController, cb) => {
  const url = `${
    LANG === "en"
      ? process.env.REACT_APP_API_EN_URL
      : process.env.REACT_APP_API_TC_URL
  }/getWebCredit`;
  post(url, postData, null, abortController, null, (err, res) => {
    if (err) {
      cb(err);
    } else {
      cb(null, res);
    }
  });
};

export const errorMessage = (LANG, postData, abortController, cb) => {
  const url = `${
    LANG === "en"
      ? process.env.REACT_APP_API_EN_URL
      : process.env.REACT_APP_API_TC_URL
  }/errorMessage`;
  post(url, postData, null, abortController, null, (err, res) => {
    if (err) {
      cb(err);
    } else {
      cb(null, res);
    }
  });
};

export const xList = (LANG, abortController, cb) => {
  const url = `${
    LANG === "en"
      ? process.env.REACT_APP_API_EN_URL
      : process.env.REACT_APP_API_TC_URL
  }/xList`;
  get(url, null, null, abortController, null, (err, res) => {
    if (err) {
      cb(err);
    } else {
      cb(null, res);
    }
  });
};

export const oembed = (LANG, url, abortController, cb) => {
  const baseUrl = `${
    LANG === "en"
      ? process.env.REACT_APP_API_EN_URL
      : process.env.REACT_APP_API_TC_URL
  }/oembed`;
  get(
    `${baseUrl}?url=${encodeURIComponent(url)}`,
    null,
    null,
    abortController,
    null,
    (err, res) => {
      if (err) {
        cb(err);
      } else {
        cb(null, res);
      }
    }
  );
};
