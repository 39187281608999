import React from "react";
import "../styles/MenuPopUp.scss";
import { useNavigate } from "react-router-dom";

const MenuPopUp = ({ popUpOnClick }) => {
  const navigate = useNavigate();
  const drawCardButtonOnClick = () => {
    navigate("/drawcard");
  };

  const historyLogoButtonOnClick = () => {
    navigate("/historylogopage");
  };

  const storyPageButtonOnClick = () => {
    navigate("/易經卜筮秘話祭祀與獻祭卜筮與人牲商朝的獻祭與信仰核心");
  };

  const takashimaPageButtonOnClick = () => {
    navigate("/日本易學大師高島吞象");
  };

  const indexButtonOnClick = () => {
    navigate("/");
    popUpOnClick();
  };

  return (
    <div className="MenuPopUp">
      <div className="MenuPopUp__closeButton" onClick={popUpOnClick}></div>
      <div className="MenuPopUp-content">
        <div
          className="MenuPopUp-content-drawCardButton"
          onClick={drawCardButtonOnClick}
        >
          <img
            src={`${process.env.PUBLIC_URL}/imgs/main02.png`}
            className="MenuPopUp-content-drawCardButton__titleTextImg"
            alt=""
          />
          <img
            src={`${process.env.PUBLIC_URL}/imgs/main08.png`}
            className="MenuPopUp-content-drawCardButton__titleImg"
            alt=""
          />
        </div>

        <img
          src={`${process.env.PUBLIC_URL}/imgs/main07.png`}
          className="MenuPopUp-content__line"
          alt=""
        />
        <div
          className="MenuPopUp-content-historyLogoButton"
          onClick={historyLogoButtonOnClick}
        >
          <img
            src={`${process.env.PUBLIC_URL}/imgs/main03.png`}
            className="MenuPopUp-content-historyLogoButton__textImg"
            alt=""
          />
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/imgs/main07.png`}
          className="MenuPopUp-content__line"
          alt=""
        />
        <div
          className="MenuPopUp-content-storyPageButton"
          onClick={storyPageButtonOnClick}
        >
          <img
            src={`${process.env.PUBLIC_URL}/imgs/main04.png`}
            className="MenuPopUp-content-storyPageButton__text2Img"
            alt=""
          />
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/imgs/main07.png`}
          className="MenuPopUp-content__line"
          alt=""
        />
        <div
          className="MenuPopUp-content-takashimaPageButton"
          onClick={takashimaPageButtonOnClick}
        >
          <img
            src={`${process.env.PUBLIC_URL}/imgs/main05.png`}
            className="MenuPopUp-content-takashimaPageButton__text2Img"
            alt=""
          />
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/imgs/main07.png`}
          className="MenuPopUp-content__line"
          alt=""
        />
        <div
          className="MenuPopUp-content-indexButton"
          onClick={indexButtonOnClick}
        >
          <img
            src={`${process.env.PUBLIC_URL}/imgs/main06.png`}
            className="MenuPopUp-content-indexButton__textImg"
            alt=""
          />
        </div>

        <img
          src={`${process.env.PUBLIC_URL}/imgs/main07.png`}
          className="MenuPopUp-content__line"
          alt=""
        />
      </div>
    </div>
  );
};

export default MenuPopUp;
