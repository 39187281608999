import React, { useState, useContext, useRef, useEffect } from "react";
import "../styles/StoryHeader.scss";
import { useNavigate } from "react-router-dom";
import MenuPopUp from "./MenuPopUp.js";

const StoryHeader = ({ children, popup }) => {
  const navigate = useNavigate();
  const [isPopUp, setIsPopUp] = useState(false);

  const menuOnClick = () => {
    setIsPopUp(true);
  };

  const popUpOnClick = () => {
    setIsPopUp(false);
  };

  const drawCardButtonOnClick = () => {
    navigate("/drawcard");
  };

  const historyLogoButtonOnClick = () => {
    navigate("/historylogopage");
  };

  const indexButtonOnClick = () => {
    navigate("/");
  };
  return (
    <>
      <div className="StoryHeader_BG">
        {popup}
        {isPopUp && <MenuPopUp popUpOnClick={popUpOnClick} />}
        <div className="StoryHeader">
          <div className="StoryHeader-content">
            <div className="StoryHeader-content-bar">
              <div
                className="StoryHeader-content-bar__menu"
                onClick={menuOnClick}
              >
                ☰
              </div>
              <img
                src={`${process.env.PUBLIC_URL}/imgs/logoN.png`}
                className="StoryHeader-content-bar__logoN"
                alt=""
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default StoryHeader;
