import React, { useState, useContext, useEffect } from "react";
import "../styles/StoryPage.scss";
import StoryHeader from "../components/StoryHeader.js";

const StoryPage = (props) => {
  return (
    <>
      <StoryHeader>
        <div className="StoryPage">
          <p>
            <h1>易經卜筮秘話</h1>
            商朝的獻祭與卜筮制度是中國古代宗教與政治的核心之一，也是易經卜筮術的起源。體現了當時人們對天命、祖先崇拜與超自然力量的信仰。
            <h2>1. 祭祀與獻祭</h2>
            商朝的祭祀主要是對天神、自然神（如河神、山神）、祖先靈魂的崇拜，而獻祭則是祭祀的重要儀式之一。獻祭的方式主要有：
            <br />
            <br />
            <strong>動物獻祭</strong>
            ：常見的祭品包括牛、羊、豬，這些動物被宰殺後，其血肉會被獻給神靈或祖先，以求庇佑。
            <br />
            <br />
            <strong>人牲獻祭</strong>
            ：根據甲骨文與考古發現，商朝確實有以活人作為祭品的情況，這種祭祀通常發生在國家重大儀式或戰爭勝利後。例如，戰俘、奴隸甚至本族人可能被用於獻祭，以向神靈或祖先表達敬畏與祈求。
            <br />
            <br />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/storyImg1.png`}
              className="StoryPage__Img1"
              alt="易經"
            />
            <br />
            <br />
            這些獻祭行為的目的，是希望透過對神靈與祖先的供奉，換取國家繁榮、農業豐收、戰爭勝利或災禍消弭。
            <br />
            <h2>2. 卜筮與人牲</h2>
            商朝的卜筮以<strong>甲骨占卜</strong>
            最為著名，主要是國王或貴族向神明請示吉凶，方法如下：
            <br />
            <ul>
              <li>取龜甲或牛肩胛骨，鑿出小洞。</li>
              <li>用火灼燒，使其產生裂紋。</li>
              <li>
                占卜師（通常是王室成員或專職巫師）根據裂紋形狀解讀神意，記錄在甲骨上。
              </li>
            </ul>
            有時，占卜結果顯示需要進行獻祭來平息神靈怒火或祈求好運，這可能涉及牲畜或人牲。例如，甲骨文中記載：「用人獻于河」，說明當時可能有向河神獻祭人牲的習俗。
            <br />
            <h2>3. 商朝的獻祭與信仰核心</h2>
            商朝的人牲獻祭是一種極為重要的宗教儀式，主要用於祭祀神靈、祖先或重要的戰爭、重大災害、國家慶典等場合。這類獻祭儀式不僅涉及到信仰，也與當時的王權、社會制度、戰爭及政治結構息息相關。被用作人牲的對象主要來自：
            <strong>戰俘、奴隸、本族人</strong>
            （特殊情況下，例如國家面臨巨大災難，可能會使用王族或貴族子弟作為祭品，以示最誠摯的敬獻）。
            <br />
            <br />
            甲骨文中，有許多關於人牲獻祭的記錄，例如「癸卯卜，用人十」表示在某天進行占卜，決定用十個人作為祭品。「用人獻于河」則是向河神獻祭的記錄。考古學家在
            <strong>殷墟遺址</strong>
            （河南安陽）發現了大量人牲坑，如武官村遺址出土了數百具人骨，顯示有大規模獻祭行為。
            <br />
            <br />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/storyImg2.jpg`}
              className="StoryPage__Img1"
              alt="易經"
            />
            <strong>
              周文王在獄中觀察學習商朝人的卜筮術，以天道推翻商朝後，
            </strong>
            周朝占卜便由甲骨占卜轉變為蓍草占卜，逐漸形成了一整套固定的占卜系統，活體祭品也被農作物、貴金屬、紙錢等取代。在長時間的實踐中，不斷積累、歸納與總結，最後形成
            <strong>《周易》的卦辭與爻辭。</strong>
            占卜方式從「灼骨裂紋」轉變為「蓍草數理推演」，其占卜過程變得更加有規律性：
            <ol>
              <li>蓍草演算：用五十根蓍草計算，得到六個爻，形成一個「卦」。</li>
              <li>
                卜問範圍擴大：不僅詢問國家大事，也開始涉及個人吉凶、農業、軍事等多方面。
              </li>
              <li>
                卦象的積累與總結：
                <ul>
                  <li>
                    透過大量的占卜實踐，逐漸發現某些卦象與某種結果的對應關係較為穩定。
                  </li>
                  <li>
                    例如，乾卦（☰☰）常與「剛健進取」相關，坤卦（☷☷）則與「柔順承受」相關。
                  </li>
                </ul>
              </li>
            </ol>
            <img
              src={`${process.env.PUBLIC_URL}/imgs/storyImg3.jpg`}
              className="StoryPage__Img1"
              alt="易經"
            />
            <br />
            <br />
            在周朝晚期，這些占卜結果逐漸被<strong>編撰與系統化</strong>
            ，形成了一整套占卜體系，最終成為《周易》的基礎。早期的占卜只是為了解答問題，但周朝的占卜師逐漸發現，
            <strong>世界的變化遵循一定的法則</strong>
            ，這才是最重要的。例如：「泰極必否，否極泰來」，說明盛極必衰，衰極必盛，這不僅是占卜結論，更是一種宇宙運行的規律，最終編撰成《周易》。這時，占卜不再是「臨時解答」，而是變成了一個可以
            <strong>預測趨勢、指導行為的智慧體系</strong>。
          </p>
        </div>
      </StoryHeader>
    </>
  );
};

export default StoryPage;
