import React, { useState, useContext, useRef, useEffect } from "react";
import "../styles/HxgHeader.scss";
import { useNavigate } from "react-router-dom";
import MenuPopUp from "./MenuPopUp.js";

const HxgHeader = ({ children, popup }) => {
  const navigate = useNavigate();
  const [isPopUp, setIsPopUp] = useState(false);

  const menuOnClick = () => {
    setIsPopUp(true);
  };

  const popUpOnClick = () => {
    setIsPopUp(false);
  };

  const drawCardButtonOnClick = () => {
    navigate("/drawcard");
  };

  const historyLogoButtonOnClick = () => {
    navigate("/historylogopage");
  };

  const indexButtonOnClick = () => {
    navigate("/");
  };
  return (
    <>
      <div className="HxgHeader_BG">
        {popup}
        {isPopUp && <MenuPopUp popUpOnClick={popUpOnClick} />}
        <div className="HxgHeader">
          <div className="HxgHeader-content">
            <div className="HxgHeader-content-bar">
              <div
                className="HxgHeader-content-bar__menu"
                onClick={menuOnClick}
              >
                ☰
              </div>

              <img
                src={`${process.env.PUBLIC_URL}/imgs/logoN.png`}
                className="HxgHeader-content-bar__logoN"
                alt=""
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/imgs/bgMoon.png`}
              className="HxgHeader-content__bgMoon"
              alt=""
            />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/bgEarth.png`}
              className="HxgHeader-content__bgEarth"
              alt=""
            />
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default HxgHeader;
