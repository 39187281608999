import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../contexts/ChatContext.js";
import "../styles/SelectHxgPage.scss";
import HxgHeader from "../components/HxgHeader.js";

const SelectHxgPage = (props) => {
  const navigate = useNavigate();
  const { question } = useContext(ChatContext);
  const [isPopup, setIsPopup] = useState(false);
  const [popupImg, setPopupImg] = useState(false);
  const [nextPage, setNextPage] = useState(false);

  useEffect(() => {
    if (!question) {
      navigate("/");
    }
  }, [question, navigate]);

  const randomHxgOnClick = () => {
    navigate("/randomhxgpage");
  };

  const onlineHxgOnClick = () => {
    setIsPopup(true);
    setPopupImg(`${process.env.PUBLIC_URL}/imgs/coin1g.png`);
    setNextPage("/onlinehxgpage");
  };

  const hxgOnClick = () => {
    setIsPopup(true);
    setPopupImg(`${process.env.PUBLIC_URL}/imgs/coin1fa.png`);
    setNextPage("/hxgpage");
  };

  const startOnClick = () => {
    navigate(nextPage);
  };

  return (
    <>
      <HxgHeader
        popup={
          <div className={`SelectHxgPagePopup ${isPopup ? "" : "hidden"}`}>
            <div className="SelectHxgPagePopup-content">
              <img
                src={popupImg}
                className="SelectHxgPagePopup-content__coin1gImg"
                alt=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/imgs/start.png`}
                className="SelectHxgPagePopup-content__startImg"
                alt=""
                onClick={startOnClick}
              />
            </div>
          </div>
        }
      >
        <img
          src={`${process.env.PUBLIC_URL}/imgs/coin0a.png`}
          className="SelectHxgPage__coin0aImg"
          alt=""
        />
        <img
          src={`${process.env.PUBLIC_URL}/imgs/coin1b.png`}
          className="SelectHxgPage__coin1bImg"
          alt=""
        />
        <img
          src={`${process.env.PUBLIC_URL}/imgs/coin1c.png`}
          className="SelectHxgPage__coin1cImg"
          alt=""
          onClick={randomHxgOnClick}
        />
        <img
          src={`${process.env.PUBLIC_URL}/imgs/coin1d.png`}
          className="SelectHxgPage__coin1dImg"
          alt=""
          onClick={onlineHxgOnClick}
        />
        <img
          src={`${process.env.PUBLIC_URL}/imgs/coin1e.png`}
          className="SelectHxgPage__coin1eImg"
          alt=""
          onClick={hxgOnClick}
        />
      </HxgHeader>
    </>
  );
};

export default SelectHxgPage;
