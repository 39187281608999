import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../contexts/ChatContext.js";
import { useIntl } from "react-intl";
import "../styles/QuestionAndMenuPage.scss";
import { sendHxg } from "../services/apiRequest.js";
import { SessionContext } from "../contexts/sessionContext.js";
import userLog from "../utils/userLog.js";
import MenuPopUp from "../components/MenuPopUp.js";
import { getSession } from "../services/apiRequest.js";

const QuestionAndMenuPage = (props) => {
  const navigate = useNavigate();
  const abortController = useRef(new AbortController());
  const intl = useIntl();
  const { LANG } = props;
  const {
    question,
    classify,
    setQuestion,
    setFilterRes,
    webSessionId,
    setWebSessionId,
    setClassify,
  } = useContext(ChatContext);
  const { userSign, browserFingerPrint } = useContext(SessionContext);

  const [padding, setPadding] = useState(false);
  const [getSessionIdPadding, setGetSessionIdPadding] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPopUp, setIsPopUp] = useState(false);
  const langMap = { en: "en", "zh-TW": "ch" };

  const filterPostData = {
    question,
    language: langMap[LANG],
    questionType: classify,
    agentId: browserFingerPrint,
  };

  useEffect(() => {
    setQuestion("");
    setClassify("other");
    getSessionId(() => {
      setGetSessionIdPadding(false);
    });
  }, []);

  const getSessionId = (cb) => {
    getSession(langMap[LANG], abortController.current, (err, res) => {
      if (err) {
        console.log(err);
      } else {
        setWebSessionId(res);
        cb();
      }
    });
  };

  const enterOnClick = () => {
    setErrorMessage("");
    if (question) startProcess();
  };

  const menuOnClick = () => {
    setIsPopUp(true);
  };

  const popUpOnClick = () => {
    setIsPopUp(false);
  };

  const startProcess = () => {
    setPadding(true);
    sendHxg(
      langMap[LANG],
      filterPostData,
      abortController.current,
      (err, res) => {
        setPadding(false);
        if (err) {
          setErrorMessage(err);
        } else {
          if (res.passed) {
            setFilterRes(res);
            userLog("Ques_short", userSign, LANG, webSessionId, (err, res) => {
              if (err) {
                console.log(err);
              }
            });
            navigate("/moreinfopage");
          } else {
            setErrorMessage(res.content);
          }
        }
      }
    );
  };

  return (
    <>
      <div className="QuestionAndMenuPage">
        {isPopUp && <MenuPopUp popUpOnClick={popUpOnClick} />}
        <div className="QuestionAndMenuPage-bar">
          <div className="QuestionAndMenuPage-bar__menu" onClick={menuOnClick}>
            ☰
          </div>

          <img
            src={`${process.env.PUBLIC_URL}/imgs/logoN.png`}
            className="QuestionAndMenuPage-bar__logoN"
            alt=""
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/imgs/main10.png`}
          className="QuestionAndMenuPage__titleImg"
          alt=""
        />
        {!getSessionIdPadding && (
          <div className="QuestionAndMenuPage-middle">
            <div className="QuestionAndMenuPage-middle-content">
              <img
                src={`${process.env.PUBLIC_URL}/imgs/questionTitle.png`}
                className="QuestionAndMenuPage-middle-content__askQuestionImg"
                alt=""
              />
              <textarea
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                className="QuestionAndMenuPage-middle-content__setQuestion metal-border"
              />
              <div className="QuestionAndMenuPage-middle-content-enter">
                <div
                  className="QuestionAndMenuPage-middle-content-enter__button"
                  onClick={enterOnClick}
                >
                  <div className="QuestionAndMenuPage-middle-content-enter__text">
                    ▶
                  </div>
                </div>
              </div>
              <div className="QuestionAndMenuPage-middle-content__info">
                {padding && (
                  <div>
                    {intl.formatMessage({ id: "ChatProcess.pleaseWait" })}
                  </div>
                )}
                {!!errorMessage && errorMessage}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QuestionAndMenuPage;
