import React, { useState, useContext, useEffect } from "react";
import "../styles/TakashimaPage.scss";
import StoryHeader from "../components/StoryHeader.js";

const TakashimaPage = (props) => {
  return (
    <>
      <StoryHeader>
        <div className="TakashimaPage">
          <p>
            <h1>日本易學大師高島吞象</h1>
            <img
              src={`${process.env.PUBLIC_URL}/imgs/takashimaImg1.png`}
              className="TakashimaPage__Img1"
              alt="易經"
            />
            高島吞象（1832年11月3日－1914年11月16日），本名高島嘉右衛門，是日本明治時期著名的易學大師，被尊稱為「易聖」。他在易經研究和實踐方面取得了卓越成就，對日本近代政治、經濟和文化產生了深遠影響。
            <br />
            <h2>早年生活與事業</h2>
            高島吞象出生於江戶（今東京），幼年體弱，但天資聰穎，過目不忘。父親從事土木建築業，但因投資失敗而債台高築。高島繼承家業後，經過努力，不僅還清債務，還取得了事業成功。他在橫濱經營木材生意，並建立了大型旅館「高島屋」，與政界和金融界人士建立了廣泛的人脈關係。
            <br />
            <h2>易經研究與貢獻</h2>
            1861年，高島因從事貨幣兌換業務被捕入獄。在獄中，他偶然獲得一本殘缺的《周易》，如獲至寶，潛心研讀，並為獄友占卜，積累了豐富的實踐經驗。出獄後，他繼續鑽研易學，並於1877年從實業界引退，專心從事易經研究。他在橫濱的高島台建立了「神易堂」，進行易經研究和占卜活動。他的占卜對象從普通民眾到政府高官，範圍廣泛。
            <br />
            <h2>《高島易斷》</h2>
            高島吞象的代表作《高島易斷》是他畢生智慧的結晶。該書深入解釋易經全文，並列舉了大量實際占卦案例，包括中日甲午戰爭等重大事件的預測。這些案例展示了他在易經占卜方面的高超技藝，對後世易學研究者具有重要的參考價值。
            高島吞象的占卜方法主要以
            <strong>蓍草占卜與六十四卦體系為基礎</strong>
            ，但與傳統的中國《易經》解讀方式相比，他的占斷更偏向
            <strong>具體事件預測</strong>，而非抽象哲學思考。
            <ul>
              <li>他的大量占卜記錄了各種重大事件的預測，如：</li>
              <li>
                <strong>甲午戰爭</strong>
                ：他曾預測日本將在對中國（清朝）的戰爭中獲勝。
              </li>
              <li>
                <strong>日本政治變革</strong>
                ：他為明治維新時期的許多政治人物占卜，並提供決策建議。
              </li>
              <li>
                這種以「歷史事件驗證占卜結果」的方式，使他的易學更具實證性，而非單純的宗教或神秘學說。
              </li>
            </ul>
            他總結自己占卜的經驗，編寫了《高島易斷》，這本書以
            <strong>具體案例解析六十四卦</strong>
            ，成為日本後世易學研究的重要參考書。他的占卜風格明快，直指結果，與中國傳統的《易經》解釋方式（多重哲學層次、強調義理）相比，更具務實性。
            <br />
            <br />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/takashimaImg2.jpg`}
              className="TakashimaPage__Img1"
              alt="易經"
            />
            <h2>影響與榮譽</h2>
            高島吞象的代表作《高島易斷》是他畢生易學研究的總結，書中收錄了超過500個實際占卜案例，涵蓋政治、軍事、商業、個人運勢等多種領域。例如，他在某次戰爭前占得
            「<strong>師卦</strong>
            」（䷆），並斷言日本將勝利，後來果然應驗。傳統的《易經》學派，如程朱理學，強調《易經》的哲學層面（如陰陽變化、道德修養）。而高島吞象更注重「如何用《易經》來解決實際問題」，這使得他的《高島易斷》成為一部
            <strong>實戰型的占卜手冊</strong>。
            高島吞象在易學上的成就，使他在日本社會享有崇高的地位。明治天皇曾親自拜訪他，並賜予他「大觀院神易吞象居士」的法名，這在當時是極高的榮譽。他於1914年逝世，享年83歲，葬於東京高輪的泉岳寺。他的貢獻對日本易學的發展產生了深遠影響，被後人尊稱為「易聖」。
            總而言之，高島吞象以其對易經的深入研究和實踐，成為日本近代易學的代表人物。他的著作《高島易斷》至今仍被視為易學研究的重要參考資料，對後世學者和愛好者提供了寶貴的指導。
          </p>
        </div>
      </StoryHeader>
    </>
  );
};

export default TakashimaPage;
