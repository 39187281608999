import React, { useState, useContext, useRef, useEffect } from "react";
import "../styles/XContent.scss";
import { xList, oembed } from "../services/apiRequest.js";

const XContent = (props) => {
  const { LANG } = props;
  const abortController = useRef(new AbortController());

  const loadTwitterWidgets = () => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    script.onload = () => {
      if (typeof window.twttr !== "undefined") {
        window.twttr.widgets.load();
      }
    };
    script.onerror = () => console.error("Twitter 小工具加載失敗");
    document.body.appendChild(script);
  };

  async function getTweetEmbeds(urls) {
    const container = document.getElementById("tweets-container");
    if (!container) {
      console.error("❌ tweets-container 元素不存在！");
      return;
    }

    container.innerHTML = ""; // 清空舊的推文

    for (const url of urls) {
      try {
        const response = oembed(
          LANG,
          url,
          abortController,
          async (err, res) => {
            if (!err) {
              // 替換 blockquote，加入 data-theme="dark"
              const darkHtml = res.html.replace(
                '<blockquote class="twitter-tweet"',
                '<blockquote class="twitter-tweet" data-theme="dark"'
              );

              // 創建 div，放入 Twitter 貼文
              const tweetDiv = document.createElement("div");
              tweetDiv.innerHTML = darkHtml;
              container.appendChild(tweetDiv);
            }
          }
        );
      } catch (error) {
        console.error(`獲取 oEmbed 失敗 (URL: ${url}):`, error);
      }
    }
  }

  function checkAndLoadTweets(maxRetries = 10) {
    let retries = 0;

    // 檢查是否還有未渲染的 blockquote
    const interval = setInterval(() => {
      const remainingTweets = document.querySelectorAll(
        "blockquote.twitter-tweet"
      ).length;
      if (remainingTweets === 0 || retries >= maxRetries) {
        clearInterval(interval); // 如果所有 blockquote 都渲染完成，停止檢查
      } else {
        retries++;
        console.log(`重試加載，第 ${retries} 次`);
        window.twttr.widgets.load();
      }
    }, 3500); // 每秒檢查一次
  }

  useEffect(() => {
    xList(LANG, abortController, async (err, res) => {
      if (!err) {
        const tweets = res.result.flat();

        const urls = [];

        tweets.map((id, index) => {
          urls.push(`https://twitter.com/pathfinderSport/status/${id}`);
        });
        getTweetEmbeds(urls);
      }
    });

    // 延遲加載 widgets.js
    setTimeout(() => {
      const existingScript = document.querySelector(
        'script[src="https://platform.twitter.com/widgets.js"]'
      );
      if (!existingScript) {
        loadTwitterWidgets();
      } else {
        if (typeof window.twttr !== "undefined") {
          window.twttr.widgets.load();
        }
      }

      checkAndLoadTweets();
    }, 500); // 延遲 500 毫秒
  }, []);

  return (
    <>
      <div className="XContent">
        <div id="tweets-container"></div>
      </div>
    </>
  );
};

export default XContent;
