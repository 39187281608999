import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../contexts/ChatContext.js";
import "../styles/TossResultPage.scss";
import HxgHeader from "../components/HxgHeader.js";

const TossResultPage = (props) => {
  const navigate = useNavigate();
  const { question, tossResult } = useContext(ChatContext);

  const buttonOnClick = () => {
    navigate("/divinationpage");
  };

  useEffect(() => {
    if (!question || !tossResult) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <HxgHeader>
        <div className="TossResultPage-result">
          <img
            src={`https://s3.ap-northeast-1.amazonaws.com/img.bot1bot.com/iching_ori_image/${tossResult}.jpg`}
            className="TossResultPage-result__resultImg"
            alt=""
          />
        </div>
        <img
          src={`${process.env.PUBLIC_URL}/imgs/div01.png`}
          className="TossResultPage__button"
          onClick={buttonOnClick}
          alt=""
        />
      </HxgHeader>
    </>
  );
};

export default TossResultPage;
