import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../contexts/ChatContext.js";
import "../styles/RandomHxgPage.scss";
import HxgHeader from "../components/HxgHeader.js";

const RandomHxgPage = (props) => {
  const navigate = useNavigate();
  const { question, setTossResult } = useContext(ChatContext);
  const [isAnimating, setIsAnimating] = useState([false, false, false]);

  const updateIsAnimatingAtIndex = (index) => {
    setIsAnimating((isAnimating) =>
      isAnimating.map((value, i) => (i === index ? !value : value))
    );
  };

  useEffect(() => {
    if (!question) {
      navigate("/");
    }
    setTossResult("");
  }, [setTossResult]);

  const coinToss = () => {
    let outcome = 0;
    for (let i = 0; i < 3; i++) {
      const toss = Math.floor(Math.random() * 2);

      outcome += toss === 0 ? 3 : 2;
    }
    return outcome;
  };

  const buttonOnClick = () => {
    let allResults = ""; // 儲存全部投擲結果

    for (var i = 0; i < 6; i++) {
      const coin = coinToss();
      allResults = `${allResults}${coin}`; // 儲存本次投擲結果
    }

    setTossResult(allResults);

    updateIsAnimatingAtIndex(0);

    setTimeout(() => {
      updateIsAnimatingAtIndex(1);
      setTimeout(() => {
        updateIsAnimatingAtIndex(2);
        setTimeout(() => {
          updateIsAnimatingAtIndex(0);
          updateIsAnimatingAtIndex(1);
          updateIsAnimatingAtIndex(2);
          navigate("/tossresultpage");
        }, 1000);
      }, 1000);
    }, 1000); // 與 CSS 的動畫時間一致
  };

  return (
    <>
      <HxgHeader>
        <img
          src={`${process.env.PUBLIC_URL}/imgs/coin0a.png`}
          className="RandomHxgPage__coin0aImg"
          alt=""
        />
        <img
          src={`${process.env.PUBLIC_URL}/imgs/coin17e.png`}
          className="RandomHxgPage__textImg"
          alt=""
        />
        <div className="RandomHxgPage-button" onClick={buttonOnClick}>
          <img
            src={`${process.env.PUBLIC_URL}/imgs/coin17a.png`}
            className={`RandomHxgPage-button__img4 ${
              isAnimating[2] ? "animate" : ""
            }`}
            alt=""
          />
          <img
            src={`${process.env.PUBLIC_URL}/imgs/coin17b.png`}
            className={`RandomHxgPage-button__img3 ${
              isAnimating[1] ? "animate" : ""
            }`}
            alt=""
          />
          <img
            src={`${process.env.PUBLIC_URL}/imgs/coin17c.png`}
            className={`RandomHxgPage-button__img2 ${
              isAnimating[0] ? "animate" : ""
            }`}
            alt=""
          />
          <img
            src={`${process.env.PUBLIC_URL}/imgs/coin17d.png`}
            className="RandomHxgPage-button__img1"
            alt=""
          />
        </div>
      </HxgHeader>
    </>
  );
};

export default RandomHxgPage;
