import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../contexts/ChatContext.js";
import "../styles/OnlineHxgPage.scss";
import HxgHeader from "../components/HxgHeader.js";

const OnlineHxgPage = (props) => {
  const navigate = useNavigate();
  const [bgImage, setBgImage] = useState(
    `url(${process.env.PUBLIC_URL}/imgs/coin3.png)`
  );
  const [visible, setVisible] = useState(true);
  const [padding, setPadding] = useState(false);
  const { question, tossResult, setTossResult } = useContext(ChatContext);
  const [imgs, setImgs] = useState(Array(6).fill(""));
  const [totalToss, setTotalToss] = useState(0);

  useEffect(() => {
    if (!question) {
      navigate("/");
    }
    setTossResult("");
  }, [setTossResult]);

  const updateImageAtIndex = (index, newValue) => {
    setImgs((prevImgs) => {
      const newImgs = [...prevImgs];
      newImgs[index] = newValue;
      return newImgs;
    });
  };

  const buttonOnClick = () => {
    if (padding) return;
    setPadding(true);
    const images = ["coin4.png", "coin5.png", "coin6.png", "coin7.png"];
    const totalRound = 2;

    for (let round = 0; round < totalRound; round++) {
      // Repeat twice
      for (let i = 0; i < images.length; i++) {
        setTimeout(() => {
          setBgImage(`url(${process.env.PUBLIC_URL}/imgs/${images[i]})`);
        }, (round * images.length + i) * 100); // Calculate total delay
      }
    }

    setTimeout(() => {
      setVisible(false);
      setTimeout(() => {
        setVisible(true);
        let cointossResult = "";
        for (let i = 0; i < 3; i++) {
          const toss = Math.floor(Math.random() * 2);
          cointossResult = `${cointossResult}${toss}`;
        }
        if (totalToss >= 0 && totalToss <= 5) {
          switch (cointossResult) {
            case "111":
              updateImageAtIndex(
                totalToss,
                totalToss >= 0 && totalToss <= 2
                  ? `${process.env.PUBLIC_URL}/imgs/coin8d.png`
                  : `${process.env.PUBLIC_URL}/imgs/coin8h.png`
              );
              setTossResult(tossResult + "9");
              setBgImage(`url(${process.env.PUBLIC_URL}/imgs/coin4.png)`);
              break;
            case "011":
              updateImageAtIndex(
                totalToss,
                totalToss >= 0 && totalToss <= 2
                  ? `${process.env.PUBLIC_URL}/imgs/coin8a.png`
                  : `${process.env.PUBLIC_URL}/imgs/coin8e.png`
              );
              setTossResult(tossResult + "8");
              setBgImage(`url(${process.env.PUBLIC_URL}/imgs/coin7.png)`);
              break;
            case "101":
              updateImageAtIndex(
                totalToss,
                totalToss >= 0 && totalToss <= 2
                  ? `${process.env.PUBLIC_URL}/imgs/coin8a.png`
                  : `${process.env.PUBLIC_URL}/imgs/coin8e.png`
              );
              setTossResult(tossResult + "8");
              setBgImage(`url(${process.env.PUBLIC_URL}/imgs/coin7.png)`);
              break;
            case "110":
              updateImageAtIndex(
                totalToss,
                totalToss >= 0 && totalToss <= 2
                  ? `${process.env.PUBLIC_URL}/imgs/coin8a.png`
                  : `${process.env.PUBLIC_URL}/imgs/coin8e.png`
              );
              setTossResult(tossResult + "8");
              setBgImage(`url(${process.env.PUBLIC_URL}/imgs/coin7.png)`);
              break;
            case "100":
              updateImageAtIndex(
                totalToss,
                totalToss >= 0 && totalToss <= 2
                  ? `${process.env.PUBLIC_URL}/imgs/coin8b.png`
                  : `${process.env.PUBLIC_URL}/imgs/coin8f.png`
              );
              setTossResult(tossResult + "7");
              setBgImage(`url(${process.env.PUBLIC_URL}/imgs/coin6.png)`);
              break;
            case "010":
              updateImageAtIndex(
                totalToss,
                totalToss >= 0 && totalToss <= 2
                  ? `${process.env.PUBLIC_URL}/imgs/coin8b.png`
                  : `${process.env.PUBLIC_URL}/imgs/coin8f.png`
              );
              setTossResult(tossResult + "7");
              setBgImage(`url(${process.env.PUBLIC_URL}/imgs/coin6.png)`);
              break;
            case "001":
              updateImageAtIndex(
                totalToss,
                totalToss >= 0 && totalToss <= 2
                  ? `${process.env.PUBLIC_URL}/imgs/coin8b.png`
                  : `${process.env.PUBLIC_URL}/imgs/coin8f.png`
              );
              setTossResult(tossResult + "7");
              setBgImage(`url(${process.env.PUBLIC_URL}/imgs/coin6.png)`);
              break;
            case "000":
              updateImageAtIndex(
                totalToss,
                totalToss >= 0 && totalToss <= 2
                  ? `${process.env.PUBLIC_URL}/imgs/coin8c.png`
                  : `${process.env.PUBLIC_URL}/imgs/coin8g.png`
              );
              setTossResult(tossResult + "6");
              setBgImage(`url(${process.env.PUBLIC_URL}/imgs/coin5.png)`);
              break;
            default:
              break;
          }
          setTotalToss(totalToss + 1);
        }

        setTimeout(() => {
          setVisible(false);
          setTimeout(() => {
            setBgImage(`url(${process.env.PUBLIC_URL}/imgs/coin3.png)`);
            setVisible(true);
            setPadding(false);
          }, 300);
        }, 1000);

        if (totalToss === 5) {
          setTimeout(() => {
            navigate("/tossresultpage"); // 3秒後導航到 /target-page
          }, 3000);
        }
      }, 300);
    }, totalRound * images.length * 100);
  };
  return (
    <>
      <HxgHeader>
        <img
          src={`${process.env.PUBLIC_URL}/imgs/coin4.png`}
          class="preload-img"
        />
        <img
          src={`${process.env.PUBLIC_URL}/imgs/coin5.png`}
          class="preload-img"
        />
        <img
          src={`${process.env.PUBLIC_URL}/imgs/coin6.png`}
          class="preload-img"
        />
        <img
          src={`${process.env.PUBLIC_URL}/imgs/coin7.png`}
          class="preload-img"
        />
        <img
          src={`${process.env.PUBLIC_URL}/imgs/coin0a.png`}
          className="OnlineHxgPage__coin0aImg"
          alt=""
        />
        <img src={imgs[5]} className="OnlineHxgPage__coin0bImg" alt="" />
        <img src={imgs[4]} className="OnlineHxgPage__coin0bImg" alt="" />
        <img src={imgs[3]} className="OnlineHxgPage__coin0bImg" alt="" />
        <div
          className={`OnlineHxgPage-button ${
            visible ? "animateFadeIn" : "animateFadeOut"
          }`}
          onClick={buttonOnClick}
          style={{ backgroundImage: bgImage }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/imgs/coin2.png`}
            className="OnlineHxgPage-button__coin2Img"
            alt=""
          />
        </div>
        <img src={imgs[2]} className="OnlineHxgPage__coin0cImg" alt="" />
        <img src={imgs[1]} className="OnlineHxgPage__coin0cImg" alt="" />
        <img src={imgs[0]} className="OnlineHxgPage__coin0cImg" alt="" />
      </HxgHeader>
    </>
  );
};

export default OnlineHxgPage;
